export default {
    SET_PROCESS_LIST(state, value) {
        state.processList = value
    },
    SET_EDIT_DRAWER(state, value) {
        state.editDrawer = value
    },
    SET_UPDATE_MODEL(state, value) {
        state.updateModel = value
    }
}